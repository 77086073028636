<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 20:03:08
 * @LastEditors: LazyQ
-->

<style lang="less" scoped>
.record-info {
  width: 100%;
  height: 100%;
  padding: 24px 33px;
  .data-table {
    width: 100%;
    border: 1px solid #e9e9e9;
    .data-table-lable {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      .lable-item {
          flex: 1;
          font-size: 14px;
          color: #666666;
          text-align: center;
      }
    }
    .data-table-empty {
      width: 100%;
      height: 40px;
      line-height: 40px;
      color: #CCCCCC;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;
    }
    .data-table-footer {
      width: 100%;
      padding: 0 14px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #999999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span {
          margin-left: 14px;
      }
    }
  }
}
.table {
  width: 100%;
  height: 40px;
}
.line {
  width: 1px;
  height: 18px;
  border-left: 1px solid #e7e7e7;
}
.color-447df5 {
  color: #447df5;
}
.bg-f4f4f4 {
  background: #f4f4f4;
}
</style>
<template>
  <div class="record-info">
    <div class="data-table">
      <div class="data-table-lable bg-f4f4f4">
        <div class="lable-item">参与时间</div>
        <div class="line"></div>
        <div class="lable-item">问卷编号</div>
        <div class="line"></div>
        <div class="lable-item">问卷名称</div>
        <div class="line"></div>
        <div class="lable-item">金币奖励</div>
        <div class="line"></div>
        <div class="lable-item">状态</div>
        <div class="line"></div>
        <div class="lable-item">返回时间</div>
        <!-- <div class="line"></div>
        <div class="lable-item">操作</div> -->
      </div>
      <div
        :class="['data-table-lable', (index + 1) % 2 === 0 ? 'bg-f4f4f4' : '']"
        v-for="(item, index) in result_list"
        :key="index"
      >
        <div class="lable-item">{{ item.create_time }}</div>
        <div class="line"></div>
        <div class="lable-item">{{ item.link_id }}</div>
        <div class="line"></div>
        <div class="lable-item">{{ item.project_title }}</div>
        <div class="line"></div>
        <div class="lable-item">{{ item.coin }}</div>
        <div class="line"></div>
        <div class="lable-item" v-if="item.result==0">未完成</div>
        <div class="lable-item" v-if="item.result==1 || item.result==2">未成功</div>
        <div class="lable-item" v-if="item.result==3">完成</div>
        <div class="line"></div>
        <div class="lable-item">{{ item.update_time }}</div>
        <!-- <div class="line"></div>
        <div class="lable-item">删除</div> -->
      </div>
      <div class="data-table-empty" v-if="result_list.length <= 0">
        没有找到匹配的记录
      </div>
      <div class="data-table-footer">
        <div>第{{ page }}/{{ pageCount }}页 共{{ inviteeCount }}条</div>
        <div>
          <span :class="{'color-447df5': beforePage}" @click="beforeClick">上一页</span><span :class="{'color-447df5': nextPage}" @click="nextClick">下一页</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userSurveylist } from "@/api/user.js"

export default {
  name: "SurveyRecord",
  data() {
    return {
      beforePage: false,
      nextPage: true,
      inviteeCount: 0,
      pageCount: 0,
      page_size: 10,
      page: 1,
      result_list: () => [],
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.fetchSurveylist()
    },
    /// 获取排行
    async fetchSurveylist() {
      try {
        let result = await userSurveylist({
          member_id: this.userId,
          token: this.userToken, 
          page_size: this.page_size,
          page: this.page
        });  
        console.log(result)
        this.inviteeCount = result.total
        this.result_list = result.result_list
        this.pageCount = Math.floor((result.total + this.page_size - 1) / this.page_size)
        /// 更新分页状态
        if (this.page <= 1) {
          this.beforePage = false;
        }
        if (this.pageCount <= this.page) {
          this.nextPage = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /// 分页
    beforeClick() {
      if (!this.beforePage) return;
      this.nextPage = true;
      if (this.page <= 1) {
        this.beforePage = false;
      }
      this.page--;
      this.fetchChangeRecord()
    },
    nextClick() {
      if (!this.nextPage) return;
      this.beforePage = true;
      if (this.page >= this.pageCount ) {
        this.nextPage = false;
      }
      this.page++;
      this.fetchChangeRecord()
    },
  },
};

</script>
