<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 20:03:08
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.view-info {
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    background-color: #f7f8fa;
    padding-bottom: 47px;
    .header-nav {
        min-width: 1200px;
        height: 73px;
        display: grid;
        grid-template-columns: 121px 121px 121px 121px 121px;
        grid-template-rows: 30px 30px 30px 30px 30px;
        grid-column-gap: 13px;
        position: relative;
        margin: 0 auto;
        .nav-item {
            width: 121px;
            height: 30px;
            line-height: 28px;
            text-align: center;
            margin-top: 25px;
            font-size: 14px;
            color: #999999;
            background-color: #F4F4F5;
            border-radius: 4px;
            border: 1px solid #CCCCCC;
        }
        .nav-item-selected {
            color: #447DF5;
            background-color: #F0F5FF;
            border: 1px solid #A2C0FF;
        }
    }
    .content-info {
        position: relative;
        margin: 0 auto;
        min-width: 1200px;
        height: 100%;
        background-color: white;
    }
}
</style>
<template>
    <div class="view-info">
        <div class="header-nav">
            <button class="nav-item"
            v-for="(item, index) in navlist" :key="item"
            @click="switchType(index)"
            :class="[
                'nav-item',
                activeIndex==index ? 'nav-item-selected' : '',
            ]"
            >
            {{ item }}
            </button>
        </div>
        <div class="content-info">
            <user-information v-if="activeIndex==0"></user-information>
            <survey-record v-if="activeIndex==1"></survey-record>
            <change-record v-if="activeIndex==2"></change-record>
            <user-setting v-if="activeIndex==3"></user-setting>
            <user-credit v-if="activeIndex==4"></user-credit>
        </div>
    </div>
</template>

<script>
import UserInformation from './UserInformation.vue';
import SurveyRecord from './SurveyRecord.vue';
import ChangeRecord from './ChangeRecord.vue';
import UserSetting from './UserSetting.vue';
import UserCredit from './UserCredit.vue'

export default {
  name: "UserInfo",
  components: {
    UserInformation,
    SurveyRecord,
    ChangeRecord,
    UserSetting,
    UserCredit
  },
  data() {
    return {
        navlist: ["个人资料", "调查记录", "竞换历史", "账户设置", "我的信用值", ],
        activeIndex: 0,
    }
  },
  created() {
    this.activeIndex = this.$route.query.index || 0
  },
  methods: {
    /// 切换类型
    switchType(index) {
        this.activeIndex = index;
    },
  }
};
</script>
