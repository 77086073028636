<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2021-03-04 22:28:07
 * @LastEditors: JiaQi
-->



<style lang="less" scoped>
.userData-info {
  width: 100%;
  height: 100%;
  padding-left: 47px;
  .information-view {
    .information-title {
      color: #333333;
      font-size: 30px;
      margin-top: 40px;
    }
    .user-info {
      display: flex;
      flex-direction: row;
      margin-top: 28px;
      .user-photo {
        width: 96px;
        height: 96px;
        border: 4px solid #d4def3;
        border-radius: 48px;
      }
      .user-information {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .user-name {
          color: #333333;
          font-size: 18px;
          font-weight: bold;
          margin-top: 0;
        }
        p {
          color: #666666;
          font-size: 14px;
          margin-top: 8px;
          .hint-title {
            color: #447df5;
            font-weight: bold;
          }
        }
      }
    }
  }
  .detail-title {
    color: #333333;
    font-size: 30px;
    margin-top: 55px;
    span {
      color: #999999;
      font-size: 16px;
      margin-left: 13px;
    }
  }
  .detail-info {
    margin: 17px 35px 60px 35px;
    ::v-deep .ant-form-item {
      margin-bottom: 12px ;
      min-height: 46px;
    }
    ::v-deep .ant-form-item-label {
      min-width: 100px;
    }
    ::v-deep .ant-form-item-label,
    label {
      color: #666666 ;
      font-size: 16px ;
    }
    ::v-deep .ant-form-item-label > label:after {
      content: "" ;
    }
    ::v-deep .ant-form-item-control-wrapper {
      display: inline-block ;
      margin-left: 40px ;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-calendar-picker,
    ::v-deep .ant-calendar-picker-input {
      min-height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-cascader-picker,
    ::v-deep .ant-cascader-input {
      min-height: 46px;
      min-width: 350px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-select-enabled,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection__rendered {
      min-height: 46px;
      // line-height: 46px;
    }
  }
  .save-button {
    width: 480px;
    height: 60px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #447df5;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>
<template>
  <div class="userData-info">
    <div class="information-view">
      <p class="information-title">基本信息</p>
      <div class="user-info">
        <img class="user-photo" src="@assets/images/avatar.png" />
        <div class="user-information">
          <p class="user-name">{{ userInfo.member_name }}</p>
          <p>注册账号：{{ userInfo.phone_number }}</p>
          <!-- <p>会员等级：<span class="hint-title">普通会员</span></p> -->
          <p>账户余额：<span class="hint-title">{{ userInfo.balance }}金币</span></p>
          <p>信用值：<span class="hint-title">{{ userInfo.credits }}信用值</span></p>
        </div>
      </div>
    </div>
    <p class="detail-title">
      详细信息<span>您的信息将完全保密，请放心填写</span>
    </p>
    <div class="detail-info">
      <template>
        <a-form-model ref="infoForm" :model="form" :rules="rules">
          <a-form-model-item label="性别" prop="sex" ref="sex">
            <a-radio-group
              v-model="form.sex"
              style="margin-left: 11px "
            >
              <a-radio value="male">男</a-radio>
              <a-radio value="female">女</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="出生年月" prop="birthDate" ref="birthDate">
            <a-date-picker
              v-model="form.birthDate"
              @change="onChangeDate"
              placeholder="请选择出生年月"
            />
          </a-form-model-item>
          <a-form-model-item label="居信地" prop="currentProvince" ref="currentProvince">
            <a-select
              v-model="form.currentProvince"
              style="width: 120px"
              @change="handleProvinceChange"
            >
              <a-select-option v-for="province in provinceData" :key="province.id">
                {{ province.area_name }}
              </a-select-option>
            </a-select>
            <a-select
                v-model="form.currentCity"
                style="width: 120px; margin-left: 15px"
              >
              <a-select-option v-for="city in cityData" :key="city.id">
                {{ city.short_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="婚姻状况" prop="marriage" ref="marriage">
            <a-select v-model="form.marriage" placeholder="请选择婚姻状况">
              <a-select-option v-for="item in marriage" :key="item.id">{{ item.item_name }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="学历" prop="school" ref="school">
            <a-select v-model="form.school" placeholder="请选择学历">
              <a-select-option v-for="item in education" :key="item.id"> {{ item.item_name }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="工作状态" prop="work" ref="work">
            <a-select v-model="form.work" placeholder="请选择工作状态">
              <a-select-option v-for="item in workStatus" :key="item.id">{{ item.item_name }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="职位" prop="job" ref="job">
            <a-select v-model="form.job" placeholder="请选择职位">
              <a-select-option v-for="item in profession" :key="item.id">{{ item.item_name }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="月薪" prop="money" ref="money">
            <a-select v-model="form.money" placeholder="请选择月薪">
              <a-select-option v-for="item in salary" :key="item.id">{{ item.item_name }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="家庭月收入"
            prop="familyMoney"
            ref="familyMoney"
          >
            <a-select v-model="form.familyMoney" placeholder="请选择家庭月收入">
              <a-select-option v-for="item in familyIncome" :key="item.id">{{ item.item_name }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="是否有私家车" prop="car" ref="car">
            <a-select v-model="form.car" placeholder="请选择汽车品牌">
              <a-select-option v-for="item in automobileBrand" :key="item.id">{{ item.item_name }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="家庭成员中是否有小孩" prop="child" ref="child">
            <a-select v-model="form.child" placeholder="请选择是否有小孩">
              <a-select-option v-for="item in childStatus" :key="item.id">{{ item.item_name }} </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </template>
      <a-button type="save" class="save-button" @click="onSubmit" :loading="loading">保存</a-button>
    </div>
  </div>
</template>

<script>
import "moment/locale/zh-cn";
import { fetchUserInfo, updateUserInfo } from "@/api/user.js"
import { getArea, fetchmarriage, fetchchildStatus, fetchAutomobileBrand, fetchFamilyIncome, fetchSalary, fetchProfession, fetchWorkStatus, fetchEducation } from "@/api/base.js";

export default {
  name: "UserInformation",
  data() {
    return {
      userInfo: () => {},
      provinceData: () => [],
      cityData: () => [],
      /// 教育
      education: () => [],
      /// 工作状态
      workStatus: () => [],
      /// 职位
      profession: () => [],
      /// 工资
      salary: () => [],
      /// 家庭收入
      familyIncome: () => [],
      /// 私家车
      automobileBrand: () => [],
      /// 是否有小孩
      childStatus: () => [],
      /// 婚姻
      marriage: () => [],
      loading: false,
      form: {
        sex: "male",
        birthDate: "",
        marriage: undefined,
        currentProvince: undefined,
        currentCity: undefined,
        school: undefined,
        work: undefined,
        job: undefined,
        money: undefined,
        familyMoney: undefined,
        car: undefined,
        child: undefined,
      },
      rules: {
        sex: {
          required: true,
          message: "",
        },
        birthDate: {
          required: true,
          message: "",
        },
        marriage: {
          required: true,
          message: "",
        },
        currentProvince: {
          required: true,
          message: "",
        },
        school: {
          required: true,
          message: "",
        },
        work: {
          required: true,
          message: "",
        },
        job: {
          required: true,
          message: "",
        },
        money: {
          required: true,
          message: "",
        },
        familyMoney: {
          required: true,
          message: "",
        },
        car: {
          required: true,
          message: "",
        },
        child: {
          required: true,
          message: "",
        },
      },
    };
  },
  created() {
    this.initData()
  },
  methods: {
    /// 初始化数据
    initData() {
      this.userInfoData();
      this.fetchArea();
      this.fetBaseData();
    },
    /// 获取用户信息
    async userInfoData() {
      try {
        let result = await fetchUserInfo({
          member_id: this.userId,
          token: this.userToken, 
        });  
        /// 同步数据
        this.userInfo = result;
        this.form.sex = result.gender == 1 ? "male" : "female"
        this.form.birthDate = result.birthday
        this.form.currentProvince = result.province
        this.form.marriage = result.marriage == 0 ? undefined : result.marriage
        this.form.school = result.education == 0 ? undefined : result.education
        this.form.work = result.work_status == 0 ? undefined : result.work_status
        this.form.job = result.profession == 0 ? undefined : result.profession
        this.form.money = result.salary == 0 ? undefined : result.salary
        this.form.familyMoney = result.family_income == 0 ? undefined : result.family_income
        this.form.car = result.automobile_brand == 0 ? undefined : result.automobile_brand
        this.form.child = result.child_status == 0 ? undefined : result.child_status
        /// 请求对应数据
        this.fetchArea(result.province);
        this.form.currentCity = result.city
      } catch (error) {
        console.log(error);
      }
    },
    /// 获取基础数据
    async fetBaseData() {
      try {
        let result = await fetchEducation()
        this.education = result.item_list
      } catch (error) {
        console.log(error)
      }
      try {
        let results = await fetchWorkStatus()
        this.workStatus = results.item_list
      } catch (error) {
        console.log(error)
      }
      try {
        let results = await fetchProfession()
        this.profession = results.item_list
      } catch (error) {
        console.log(error)
      }
      try {
        let results = await fetchSalary()
        this.salary = results.item_list
      } catch (error) {
        console.log(error)
      }
      try {
        let results = await fetchFamilyIncome()
        this.familyIncome = results.item_list
      } catch (error) {
        console.log(error)
      }
      try {
        let results = await fetchAutomobileBrand()
        this.automobileBrand = results.item_list
      } catch (error) {
        console.log(error)
      }
      try {
        let results = await fetchchildStatus()
        this.childStatus = results.item_list
      } catch (error) {
        console.log(error)
      }
      try {
        let results = await fetchmarriage()
        this.marriage = results.item_list
      } catch (error) {
        console.log(error)
      }
    },
    /// 获取区域
    async fetchArea(parentId) {
      try {
        let area = await getArea({
          parent_id: parentId,
          page_size: 100
        });  
        if (parentId != undefined) {
          this.cityData = area.area_list;
        }else {
          this.provinceData = area.area_list;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /// 更改日历
    onChangeDate(date, dateString) {
      this.birthDate = dateString + " 00:00:00"
    },
    /// 城市选择切换
    handleProvinceChange(value) {
      this.fetchArea(value)
    },
    onSubmit() {
      this.$refs.infoForm.validate((valid) => {
        if (valid) {
          this.updateUserInfo()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /// 更新用户信息
    async updateUserInfo() {
      this.loading = true;
      try {
        let result = await updateUserInfo({
          gender: this.form.sex == 'male' ? 1 : 2,
          birthday: this.form.birthDate,
          province: this.form.currentProvince,
          city: this.form.currentCity,
          marriage: this.form.marriage,
          education: this.form.school,
          work_status: this.form.work,
          profession: this.form.job,
          salary: this.form.money,
          family_income: this.form.familyMoney,
          automobile_brand: this.form.car,
          child_status: this.form.child
        });
        this.userInfoData()
        this.loading = false;
        this.$message.success("信息保存成功");
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    }
  },
};

</script>
