<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 20:03:08
 * @LastEditors: LazyQ
-->

<style lang="less" scoped>
.credit-info {
  width: 100%;
  height: 100%;
  padding: 24px 33px;
  .credit-title {
    font-size: 20px;
    color: #333333;
    span {
      color: #447DF5;
      font-size: 40px;
      font-weight: bold;
    }
  }
  .data-table {
    width: 100%;
    border: 1px solid #e9e9e9;
    margin-top: 10px;
    .data-table-lable {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      .lable-item {
        flex: 1;
        font-size: 14px;
        color: #666666;
        text-align: center;
      }
    }
    .data-table-empty {
      width: 100%;
      height: 40px;
      line-height: 40px;
      color: #CCCCCC;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;
    }
    .data-table-footer {
      width: 100%;
      padding: 0 14px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #999999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span {
          margin-left: 14px;
      }
    }
  }
}
.hint-info {
  margin-top: 43px;
  color: #666666;
  font-size: 16px;
  .hint-title {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
  }
}
.status-bg {
  background-color: #447DF5;
  padding: 3px 5px;
  color: white;
  font-size: 12px;
  border-radius: 4px;
}
.bg-E52C3E {
  background-color: #E52C3E;
}
.line {
  width: 1px;
  height: 18px;
  border-left: 1px solid #e7e7e7;
}
.color-447df5 {
  color: #447df5;
}
.bg-f4f4f4 {
  background: #f4f4f4;
}
</style>
<template>
  <div class="credit-info">
    <p class="credit-title">我的信用值 <span>{{ userInfo.credits }}</span></p>
    <div class="data-table">
      <div class="data-table-lable bg-f4f4f4">
        <div class="lable-item">发生时间</div>
        <div class="line"></div>
        <div class="lable-item">信用值</div>
        <div class="line"></div>
        <div class="lable-item">状态</div>
        <div class="line"></div>
        <div class="lable-item">信用描述</div>
      </div>
      <div
        :class="['data-table-lable', (index + 1) % 2 === 0 ? 'bg-f4f4f4' : '']"
        v-for="(item, index) in credit_log_list"
        :key="item.credit_log_id"
      >
        <div class="lable-item">{{ item.create_time }}</div>
        <div class="line"></div>
        <div class="lable-item">{{ item.credit_value }}</div>
        <div class="line"></div>
        <div class="lable-item" v-if="item.operation==1"><span class="status-bg">增加</span></div>
        <div class="lable-item" v-if="item.operation==2"><span class="status-bg bg-E52C3E">减少</span></div>
        <div class="line"></div>
        <div class="lable-item">{{ item.description.length == 0 ? "无" : item.description }}</div>
      </div>
      <div class="data-table-empty" v-if="credit_log_list.length <= 0">
        没有找到匹配的记录
      </div>
      <div class="data-table-footer">
        <div>第{{ page }}/{{ pageCount }}页 共{{ inviteeCount }}条</div>
        <div>
          <span :class="{'color-447df5': beforePage}" @click="beforeClick">上一页</span><span :class="{'color-447df5': nextPage}" @click="nextClick">下一页</span>
        </div>
      </div>
    </div>
    <div class="hint-info">
      <p class="hint-title">如何提高信用值：</p>
      <p style="margin-top: 8px">1、认真回答问卷调查，问卷被客户审核无效问卷将会大大降低信用值。<span class="color-447df5" style="text-decoration: underline;" @click="goQuestionnaire">点我参加问卷调查</span></p>
      <p>2、完善好个人的背景资料将有利于提升信用值。<span class="color-447df5" style="text-decoration: underline;" @click="goPerfectInfo">点我去完善资料</span></p>
    </div>
  </div>
</template>

<script>
import { userCreditloglist, fetchUserInfo } from "@/api/user.js"

export default {
  name: "UserCredit",
  data() {
    return {
      creditValue: 0,
      beforePage: false,
      nextPage: true,
      inviteeCount: 0,
      pageCount: 0,
      page_size: 10,
      page: 1,
      credit_log_list: () => [],
      userInfo: () => {},
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.userInfoData()
      this.fetchCreditloglist()
    },
    /// 获取用户信息
    async userInfoData() {
      try {
        let result = await fetchUserInfo({
          member_id: this.userId,
          token: this.userToken, 
        });  
        /// 同步数据
        this.userInfo = result;
      } catch (error) {
        console.log(error);
      }
    },
    /// 获取排行
    async fetchCreditloglist() {
      try {
        let result = await userCreditloglist({
          member_id: this.userId,
          token: this.userToken, 
          page_size: this.page_size,
          page: this.page
        });  
        console.log(result)
        this.inviteeCount = result.total
        this.credit_log_list = result.credit_log_list
        this.pageCount = Math.floor((result.total + this.page_size - 1) / this.page_size)
        /// 更新分页状态
        if (this.page <= 1) {
          this.beforePage = false;
        }
        if (this.pageCount <= this.page) {
          this.nextPage = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /// 分页
    beforeClick() {
      if (!this.beforePage) return;
      this.nextPage = true;
      if (this.page <= 1) {
        this.beforePage = false;
      }
      this.page--;
      this.fetchChangeRecord()
    },
    nextClick() {
      if (!this.nextPage) return;
      this.beforePage = true;
      if (this.page >= this.pageCount ) {
        this.nextPage = false;
      }
      this.page++;
      this.fetchChangeRecord()
    },
    /// 问卷调查
    goQuestionnaire() {
      this.$router.push({ name: "Survey" });
    },
    /// 完善资料
    goPerfectInfo() {
      location.reload()
    }
  },
};

</script>
