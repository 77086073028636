<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2021-03-04 22:31:06
 * @LastEditors: JiaQi
-->
<style>
/* 样式覆盖 */
</style>

<style lang="less" scoped>
.setting-info {
  width: 100%;
  height: 100%;
  padding: 30px 47px;
  ::v-deep .ant-tabs-nav,
  ::v-deep .ant-tabs-tab {
    color: #333333;
    font-size: 30px;
    font-weight: 400;
  }
  ::v-deep .ant-tabs-nav,
  ::v-deep .ant-tabs-tab-active {
    color: #447df5;
    font-size: 30px;
    font-weight: 400;
  }
  .info-content {
    margin-top: 20px;
    ::v-deep .ant-form-item {
      margin-bottom: 12px;
      height: 46px;
    }
    ::v-deep .ant-form-item-label {
      width: 100px;
    }
    ::v-deep .ant-form-item-label,
    label {
      color: #666666;
      font-size: 16px;
    }
    ::v-deep .ant-form-item-label > label:after {
      content: "";
    }
    ::v-deep .ant-form-item-control-wrapper {
      display: inline-block;
      margin-left: 5px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-input {
      height: 46px;
      line-height: 46px;
      color: #333333;
      font-size: 16px;
    }
  }
}
.code-button {
  width: 124px;
  height: 46px;
  border-radius: 3px;
  background-color: #447cf510;
  border-color: #447df5;
  border-width: 1px;
  color: #447df5;
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
}
.save-button {
  width: 460px;
  height: 60px;
  margin-top: 10px;
  border-radius: 4px;
  background-color: #447df5;
  color: white;
  font-size: 24px;
  font-weight: bold;
}
.remark-info {
  margin-top: 24px;
  color: #333333;
  font-size: 14px;
}
.hint-info {
  margin-top: 43px;
  color: #666666;
  font-size: 16px;
  .hint-title {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
<template>
  <div class="setting-info">
    <div>
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="修改密码">
          <div class="info-content">
            <template>
              <a-form-model ref="pwdForm" :model="form" :rules="rules">
                <a-form-model-item
                  label="初始密码"
                  prop="initPwd"
                  ref="initPwd"
                >
                  <a-input
                    v-model="form.initPwd"
                    placeholder="请输入初始密码"
                  />
                </a-form-model-item>
                <a-form-model-item label="新密码" prop="newPwd" ref="newPwd">
                  <a-input v-model="form.newPwd" placeholder="请输入新密码" />
                </a-form-model-item>
                <a-form-model-item
                  label="确认密码"
                  prop="affirmPwd"
                  ref="affirmPwd"
                >
                  <a-input
                    v-model="form.affirmPwd"
                    placeholder="请输入确认密码"
                  />
                </a-form-model-item>
              </a-form-model>
            </template>
            <button type="save" class="save-button" @click="onSubmit">
              保存
            </button>
            <div class="hint-info">
              <p class="hint-title">注意事项：</p>
              <p>1.定期修改密码，有利于账户安全。</p>
              <p>如果密码不记得可以通过邮箱找回密码。</p>
            </div>
          </div>
        </a-tab-pane>
        <!-- <a-tab-pane key="2" tab="手机激活" force-render>
          <div class="info-content">
            <template>
              <a-form-model ref="phoneForm" :model="form" :rules="rules">
                <a-form-model-item label="手机号" prop="phone" ref="phone">
                  <a-input
                    v-model="form.phone"
                    placeholder="请输入手机号"
                    style="width: 200px"
                  />
                  <button class="code-button" @click="fetchCode">
                    获取验证码
                  </button>
                </a-form-model-item>
                <a-form-model-item label="验证码" prop="code" ref="code">
                  <a-input v-model="form.code" placeholder="请输入验证码" />
                </a-form-model-item>
              </a-form-model>
            </template>
            <button type="save" class="save-button" @click="onPhoneSubmit">
              保存
            </button>
            <p class="remark-info">
              1.手机验证主要是为了在兑换过程中实名认证，以保护您的账号安全。<br />2.请输入您的常用手机号码，手机验证通过将不允许修改。如您已更换手机号码，请联系客服人员。
            </p>
            <div class="hint-info">
              <p class="hint-title">注意事项：</p>
              <p>
                1.接收手机验证码短信免费；手机验证不会产生任何其他费用；<br />2.验证码一天最多发送3次，如果3次都收不到，可能是因为短信通道堵塞，需改天再验证；
                <br />3.为保证验证码安全，验证码的有效期为24小时；<br />4.一个手机号码只能验证一个账号；<br />5.如您一直无法收到验证码，请关注“蚂蚁调研网”微信公众号，她会全程为你服务。
              </p>
            </div>
          </div>
        </a-tab-pane> -->
        <a-tab-pane key="2" tab="支付宝设置">
          <div class="info-content">
            <template>
              <a-form-model ref="payForm" :model="form" :rules="rules">
                <a-form-model-item label="姓名" prop="name" ref="name">
                  <a-input v-model="form.name" placeholder="请输入姓名" />
                </a-form-model-item>
                <a-form-model-item label="支付宝账号" prop="payID" ref="payID">
                  <a-input
                    v-model="form.payID"
                    placeholder="请输入支付宝账号"
                  />
                </a-form-model-item>
              </a-form-model>
            </template>
            <!-- :disabled="!isHaveAccount" -->
            <!-- v-show="isHaveAccount" -->
            <a-button type="save" class="save-button" @click="visible=true">保存</a-button>
            <!-- <p class="remark-info">
              此支付宝账号录入后，个人将不可修改。如需修改支付宝账号，请发送电子邮件到<span style="color: #447df5">32361398@qq.com</span>
              <br/>邮件中说明自己的注册用手机号或用户名，旧的支付宝账号以及新的支付宝账号。 谢谢您！
            </p> -->
            <div class="hint-info">
              <p class="hint-title">注意事项：</p>
              <p>
                1.为了您的资金安全，支付宝姓名必须是注册时填写的姓名。<br />2.支付宝一旦填写就不能再更改，以后您兑换的现金都会转账到此支付宝中。
                <br />3.如您遇到任何特殊问题，请联系我们<span
                  style="color: #447df5"
                  >客服MM</span
                >，她会全程为你服务。
              </p>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-modal
      :visible="visible"
      :confirm-loading="payloading"
      title="提示"
      ok-text="确认"
      cancel-text="取消"
      @cancel="visible=false"
      @ok="onPaySubmit"
    >
      <p>支付宝提交认证后，将不再允许修改。蚂蚁调研网今后会将现金发放到此账户，请认真填写。</p>
    </a-modal>
  </div>
</template>

<script>
import { updateUserPwd, updateUserInfo, fetchUserInfo } from "@/api/user.js"
import md5 from 'js-md5';

export default {
  name: "UserSetting",
  data() {
    return {
      isHaveAccount: true,
      payloading: false,
      visible: false,
      form: {
        initPwd: undefined,
        newPwd: undefined,
        affirmPwd: undefined,
        phone: undefined,
        code: undefined,
        name: undefined,
        payID: undefined,
      },
      rules: {
        initPwd: {
          required: true,
          message: "",
        },
        newPwd: {
          required: true,
          message: "",
        },
        affirmPwd: {
          required: true,
          message: "",
        },
        phone: {
          required: true,
          message: "",
        },
        code: {
          required: true,
          message: "",
        },
        name: {
          required: true,
          message: "",
        },
        payID: {
          required: true,
          message: "",
        },
      },
    };
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.userInfoData()
    },
    /// 修改密码验证
    onSubmit() {
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          if (this.form.newPwd != this.form.affirmPwd) {
            return this.$message.error("请再次两次密码是否一致");_
          }
          this.changePwd()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /// 获取排行
    async changePwd() {
      try {
        let result = await updateUserPwd({
          password: md5(this.form.initPwd),
          new_pass: md5(this.form.newPwd)
        });
        /// 修改成功后重置UI
        this.form.initPwd = "";
        this.form.newPwd = "";
        this.form.affirmPwd = "";
        this.$message.success("密码修改成功");
      } catch (error) {
        console.log(error);
      }
    },
    /// 获取验证码验证
    fetchCode() {
      this.$refs.phone.validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /// 手机号设置验证
    onPhoneSubmit() {
      this.$refs.phoneForm.validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /// 支付账号验证
    onPaySubmit() {
      this.$refs.payForm.validate((valid) => {
        if (valid) {
          this.updateUserInfo()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /// 更新用户信息
    async updateUserInfo() {
      this.payloading = true;
      try {
        let result = await updateUserInfo({
          member_name: this.form.name,
          alipay_account: this.form.payID,
        });
        this.userInfoData()
        this.payloading = false;
        this.visible = false;
        this.$message.success("信息保存成功");
      } catch (error) {
        this.payloading = false;
        console.log(error);
      }
    },
    /// 获取用户信息
    async userInfoData() {
      try {
        let result = await fetchUserInfo({
          member_id: this.userId,
          token: this.userToken, 
        });  
        /// 同步数据
        this.form.payID = result.alipay_account
        this.form.name = result.member_name
        if (result.alipay_account == "" || result.alipay_account == undefined) {
          this.isHaveAccount = true
        }else {
          this.isHaveAccount = false
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
